<template>
  <window-content v-if="!loading && !errors">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 190px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input  v-model="comitente.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <tipo-pessoa-select label="Tipo Pessoa:" field-view="lr" simple-border="" v-model="comitente.pessoa.type" />
        </e-col>

        <e-col style="max-width: 340px">
          <document-input :pessoa="comitente.pessoa" @mergePessoa="(p) => comitente.pessoa = p" />
        </e-col>

        <e-col style="max-width: 260px">
          <status-select label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                         v-model="comitente.active" />
        </e-col>

      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="comitente.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input simple-border v-model="comitente.pessoa.name" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 230px">
          <erp-s-field
              view="lr"
              label="Apelido:"
              label-width="50px"
          >
            <apelido-input :nome="comitente.pessoa.name" :gerar-blur="!comitente.id"
                           v-model="comitente.apelido" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 260px;">
          <tipo-comitente-select label="Tipo:" field-view="lr" simple-border="" v-model="comitente.tipo" />
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input simple-border v-model="comitente.pessoa.emails[0].email" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Celular:"
              label-width="50px"
          >
            <erp-input simple-border v-model="comitente.pessoa.phoneNumbers[0].phoneNumber"
                       v-mask="'(##) # ####-####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Residencial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="comitente.pessoa.phoneNumbers[1].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Comercial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="comitente.pessoa.phoneNumbers[2].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="dadosBancarios">Dados Bancários</erp-tab-item>
      <erp-tab-item name="endereco">Endereço</erp-tab-item>
      <erp-tab-item name="contato">Contato</erp-tab-item>
      <erp-tab-item name="logs">Logs</erp-tab-item>
      <erp-tab-item name="automacao">Automação</erp-tab-item>
      <erp-tab-item name="acesso" v-if="comitente.id">Acesso</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-if="isPessoaFisica" mr>
              <e-col style="min-width: 280px">
                <erp-s-field
                    view="lr"
                    label="RG:"
                    label-width="90px"
                >
                  <erp-input v-model="comitente.pessoa.documents[0].identifier" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Emissor:"
                >
                  <erp-input v-model="comitente.pessoa.documents[0].agentDispatcher" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Expedição:"
                >
                  <erp-input v-model="comitente.pessoa.documents[0].expedition" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nascimento:"
                    label-width="90px"
                >
                  <erp-input v-model="comitente.pessoa.birthDate" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nacionalidade:"
                >
                  <erp-input v-model="comitente.pessoa.nationality" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Sexo:"
                >
                  <erp-select placeholder="Selecione"
                              :options="[{label: 'Masculino', value: 1},{label: 'Feminino', value: 2}]"
                              v-model="comitente.pessoa.gender" />
                </erp-s-field>
              </e-col>
            </e-row>

            <!--<e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="comitente.permitirCompraBlindado">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar compra de blindados</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>-->
            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="comitente.newsletter">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Inscrito para newsletter</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row class="m-t-xs" mr>
              <e-col>
                <grupos-select
                    field-view="lr"
                    label-width="90px"
                    label="Grupo(s):"
                    class="wrap label-wrap"
                    @loaded="$emit('loadedGrupos')" multiple :columns="[
                      {label: 'Nome', value: 'label'}
                    ]" v-model="comitente.grupos" />
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Descrição interna:"
                    class="wrap label-wrap"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="comitente.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="comitente.prazoRemocao">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Prazo para Remoção</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Tempo Limite:"
                    class="wrap label-wrap"
                >
                  <erp-input :disable="!comitente.prazoRemocao" v-model="comitente.prazoRemocaoHoras" type="number" placeholder="* Em horas" />
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <imagem label="Logomarca" legend="De preferência PNG 200x80px" ref="imagem" :image="comitente.image"
                    @update="updateImagem" @toDelete="deleteImagemEvent" />
          </e-col>
        </e-row>

        <!--<e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm font-11">Após o cadastro ou alteração:</div>
            <e-row>
              <e-col>
                <erp-checkbox class="flex no-wrap items-center m-r" v-model="comitente.enviarBoasVindas">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Enviar e-mail de boas vindas</span>
                  </label>
                </erp-checkbox>

                <erp-checkbox class="flex no-wrap items-center m-r" v-model="comitente.solicitarCriacaoSenha">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Criar acesso e solicitar criação de senha</span>
                  </label>
                </erp-checkbox>
              </e-col>
            </e-row>
          </e-col>
        </e-row>-->
      </div>
      <div v-show="tabActive === 'dadosBancarios'" class="wrapper m-t">
        <e-row>
          <e-col style="max-width: 300px">
            <erp-s-field
                view="tl"
                label="Forma de Repasse de Valores:"
            >
              <erp-select placeholder="Selecione"
                          :options="[{label: 'Repasse', value: false},{label: 'Depósito direto para o comitente', value: true}]"
                          v-model="comitente.depositoDireto" />
            </erp-s-field>
          </e-col>
        </e-row>
        <div v-for="(dadoBancario, dindex) in comitente.dadosBancarios" :key="dindex" class="e-input-modern size1">
          <e-row mr class="m-t" style="max-width: 470px">
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Código do Banco:"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.codigoBanco" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Nome do Banco:"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.banco" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Agência:"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.agencia" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Conta:"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.conta" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Favorecido:"
                  icon-help="Somente em caso do favorecido ser diferente do nome do comitente cadastrado"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.favorecido" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Documento do Favorecido:"
                  icon-help="Somente em caso do favorecido ser diferente do nome do comitente cadastrado"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.documentoFavorecido" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Chave PIX:"
                  class="wrap label-wrap"
              >
                <erp-input v-model="dadoBancario.chavePix" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr class="m-t">
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Observações de pagamento para arrematantes:"
                  class="wrap label-wrap"
              >
                    <textarea class="w-full sl-textarea wrapper-xs" rows="4"
                              v-model="dadoBancario.observacoes"></textarea>
              </erp-s-field>
            </e-col>
          </e-row>
        </div>
      </div>
      <endereco-v2 v-show="tabActive === 'endereco'" class="wrapper m-t" :pessoa="comitente.pessoa"/>
      <div v-show="tabActive === 'contato'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-for="(telefone, index) in comitente.pessoa.phoneNumbers" :key="telefone.id">
              <e-col>
                <e-row v-if="index === 0" mr>
                  <e-col style="max-width: 280px">
                    <erp-s-field
                        view="lr"
                        label=""
                        label-width="90px"
                    >
                      <div class="font-11 font-bold m-b">Telefones</div>
                    </erp-s-field>
                  </e-col>
                </e-row>
                <e-row v-if="index > 2" class="m-b-sm" mr>
                  <e-col style="max-width: 280px">
                    <tipo-telefone-select field-view="lr" label-width="90px" v-model="telefone.purpose" />
                  </e-col>
                  <e-col class="flex items-center">
                    <a class="text-negative" @click="removeTelefone(telefone)">remover este telefone</a>
                  </e-col>
                </e-row>
                <e-row class="m-t-n" mr>
                  <e-col style="max-width: 280px">
                    <erp-s-field
                        view="lr"
                        :label="getTelefoneLabel(index)"
                        label-width="90px"
                    >
                      <erp-input v-if="index > 2" size="2" v-model="telefone.phoneNumber" v-mask="'(##) # ####-####'" />
                      <div v-else>{{telefone.phoneNumber|formataTelefone}}</div>
                    </erp-s-field>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
            <e-btn @click="adicionarTelefone" style="margin-left: 102px" label="Adicionar novo telefone" />
            <!-- -->
            <e-col>
              <e-row class="m-t-lg" mr>
                <e-col style="max-width: 280px">
                  <erp-s-field
                      view="lr"
                      label=""
                      label-width="90px"
                  >
                    <div class="font-11 font-bold">Outros contatos</div>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row v-for="(contato, index) in comitente.pessoa.contacts" :key="contato.id">
                <e-col>
                  <e-row class="m-b-sm" mr>
                    <e-col style="max-width: 280px">
                      <tipo-contato-select field-view="lr" size="2" label="Tipo:" label-width="90px"
                                           v-model="contato.purpose" />
                    </e-col>
                    <e-col class="flex items-center">
                      <a class="text-negative" @click="removeContato(contato)">remover este contato</a>
                    </e-col>
                  </e-row>
                  <e-row class="m-t-n" mr>
                    <e-col style="max-width: 340px">
                      <erp-s-field
                          view="lr"
                          label="Contato"
                          label-width="90px"
                      >
                        <erp-input size="2" v-model="contato.value" />
                      </erp-s-field>
                    </e-col>
                  </e-row>
                </e-col>
              </e-row>
              <e-btn @click="adicionarContato" style="margin-left: 102px" label="Adicionar novo contato" />
            </e-col>
          </e-col>
          <e-col>
            <e-row mr>
              <e-col style="max-width: 280px">
                <erp-s-field
                    view="lr"
                    label=""
                    label-width="90px"
                >
                  <div class="font-11 font-bold m-b">E-mails</div>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="m-t-n" mr>
              <e-col style="max-width: 340px">
                <erp-s-field
                    view="lr"
                    label="Principal:"
                    label-width="90px"
                >
                  <erp-input size="2" v-model="comitente.pessoa.emails[0].email" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row v-for="(email, index) in comitente.pessoa.emails" :key="email.id">
              <e-col v-if="index > 0">
                <e-row class="m-b-sm" mr>
                  <e-col style="max-width: 280px">
                    <tipo-email-select field-view="lr" size="2" label=" " label-width="90px" v-model="email.purpose" />
                  </e-col>
                  <e-col class="flex items-center">
                    <a class="text-negative" @click="removeEmail(email)">remover este email</a>
                  </e-col>
                </e-row>
                <e-row class="m-t-n" mr>
                  <e-col style="max-width: 340px">
                    <erp-s-field
                        view="lr"
                        label=""
                        label-width="90px"
                    >
                      <erp-input size="2" v-model="email.email" />
                    </erp-s-field>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
            <e-btn @click="adicionarEmail" style="margin-left: 102px" label="Adicionar novo e-mail" />
          </e-col>
        </e-row>
      </div>
      <div v-if="tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="comitente" type="comitente" />
      </div>
      <div v-show="tabActive === 'acesso'" class="wrapper m-t">
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm">Acesso</div>
            <acesso :comitente="comitente" :router="router" />
          </e-col>
        </e-row>
      </div>
      <div v-if="tabActive === 'automacao'" class="wrapper m-t">
        <e-row mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Modelo de Cobrança de Arremate:"
            >
              <documento-select :only-value="false" code="comunicacao.arrematante.cobranca-arremate" v-model="comitente.config.templateCobranca" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                view="tl"
                label="Modelo de Fatura:"
            >
              <documento-select :only-value="false" code="documentos-leilao-recibos-pagamento-lote" v-model="comitente.config.templateFatura" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Modelo de Cobrança de Arremate:"
            >
              <documento-select :only-value="false" code="documentos-leilao-notas-arrematacao" v-model="comitente.config.templateNota" />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newComitente,
  updateComitente,
  updateFotoComitente
} from '../../../../domain/comitentes/services/index'
import {removePersonObject} from '@/domain/pessoa/domain'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../include/StatusComitenteSelect'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoComitenteSelect from '../include/TipoComitenteSelect'
import ApelidoInput from '../../../arrematante/components/include/ApelidoInput'
// import {EstadoCivil, ESTADO_CIVIL_CASADO} from '../../../../domain/arrematante/helpers/Status'
import Acesso from '../include/Acesso'
import TipoEnderecoSelect from '../../../pessoa/components/include/TipoEnderecoSelect'
import CidadeSelect from '../../../remocao/components/include/CidadeSelect'
import UfSelect from '../../../remocao/components/include/UfSelect'
import TipoTelefoneSelect from '../../../pessoa/components/include/TipoTelefoneSelect'
import TipoEmailSelect from '../../../pessoa/components/include/TipoEmailSelect'
import TipoContatoSelect from '../../../pessoa/components/include/TipoContatoSelect'
import Log from '../../../usuario/components/include/Log'
import {adjustPurposes} from '@/domain/pessoa/helpers/Purpose'
import {TIPO_PESSOA_JURIDICA} from '@/domain/pessoa/helpers/Status'
import Imagem from '../../../../reuse/upload/Imagem'
import TipoPessoaSelect from '../../../pessoa/components/include/TipoPessoaSelect'
import EnderecoV2 from '../../../pessoa/components/include/EnderecoV2'

import {mockEndereco} from '../../../pessoa/mock/endereco'
import {mockTelefone} from '../../../pessoa/mock/telefone'
import {mockEmail} from '../../../pessoa/mock/email'
import {mockContato} from '../../../pessoa/mock/contato'
import {mockDocument} from '../../../pessoa/mock/document'
import GruposSelect from '@/components/comitente/components/include/GruposSelect'
import uid from 'uloc-vue/src/utils/uid.js'
import DocumentoSelect from "components/documentos/components/include/DocumentoSelect"
import DocumentInput from "components/pessoa/components/include/DocumentInput"

const mockDadosBancarios = {
  codigoBanco: null,
  banco: null,
  agencia: null,
  conta: null,
  favorecido: null,
  documentoFavorecido: null,
  chavePix: null,
  observacoes: null,
  active: true
}

const mockConfig = {
  templateCobranca: null,
  templateFatura: null,
  templateNota: null,
}

let mockComitente = {
  id: null,
  apelido: null,
  newsletter: true,
  observacao: null,
  tipo: null,
  grupos: [],
  pessoa: {
    id: null,
    name: null,
    type: 2,
    document: null,
    birthDate: null,
    nationality: null,
    gender: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone))],
    emails: [JSON.parse(JSON.stringify(mockEmail))],
    contacts: [JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato))],
    documents: [JSON.parse(JSON.stringify(mockDocument))]
  },
  enviarBoasVindas: true,
  depositoDireto: false,
  solicitarCriacaoSenha: true,
  status: 0,
  active: true,
  prazoRemocao: false,
  prazoRemocaoHoras: null,
  dadosBancarios: [mockDadosBancarios],
  config: mockConfig
}

export default {
  name: 'ComitenteWindow',
  provide: function () {
    return {
      container: this
    }
  },
  directives: {money: VMoney},
  props: ['id', 'router'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteImagem: false,
      comitente: JSON.parse(JSON.stringify(mockComitente))
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    /* EstadoCivil () {
      return EstadoCivil
    },
    EstadoCivilCasado () {
      return ESTADO_CIVIL_CASADO
    }, */
    isPessoaFisica () {
      return this.comitente.pessoa.type === 1
    },
    isEmpresa () {
      return this.comitente.pessoa.type === 2
    }
  },
  watch: {},
  methods: {
    load () {
      (this.id || this.comitente.id) && find(this.id || this.comitente.id)
        .then(response => {
          let data = response.data

          if (data.tipo && data.tipo.id) {
            data.tipo = data.tipo.id
          }

          if (data.grupos && Array.isArray(data.grupos)) {
            data.grupos = data.grupos.map(g => {
              return g.id
            })
          } else{
            data.grupos = []
          }

          if (data.pessoa.birthDate && data.pessoa.birthDate.date) {
            data.pessoa.birthDate = date.formatDate(data.pessoa.birthDate.date, 'DD/MM/YYYY')
          }
          // let mock = JSON.parse(JSON.stringify(mockComitente))
          // console.log(mock)
          // this.comitente = Object.assign(mock, data)
          // console.log(this.comitente)
          if (!data.pessoa.addresses || data.pessoa.addresses.length === 0) {
            data.pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
          }

          if (!data.pessoa.phoneNumbers || data.pessoa.phoneNumbers.length < 3) {
            if (!Array.isArray(data.pessoa.phoneNumbers)) {
              data.pessoa.phoneNumbers = []
            }
            console.log(data.pessoa.phoneNumbers.length)
            const len = 3 - Number(data.pessoa.phoneNumbers.length)
            for (let i = 0; i < len; i++) {
              data.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
            }
            console.log(data.pessoa.phoneNumbers)
          }

          if (!data.pessoa.emails || data.pessoa.emails.length === 0) {
            data.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
          }

          if (!data.pessoa.documents || data.pessoa.documents.length === 0) {
            data.pessoa.documents = [JSON.parse(JSON.stringify(mockDocument))]
          }

          if (!data.dadosBancarios || !data.dadosBancarios.length) {
            data.dadosBancarios = [mockDadosBancarios]
          }
          if (!data.config) {
            data.config = mockConfig
          } else {
            try {
              data.config = JSON.parse(data.config)
            } catch (e) {
              data.config = mockConfig
            }
          }
          adjustPurposes([
            data.pessoa.addresses,
            data.pessoa.phoneNumbers,
            data.pessoa.contacts,
            data.pessoa.emails
          ])
          this.comitente = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o arremtante, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      let data = JSON.parse(JSON.stringify(this.comitente))

      if (data.pessoa?.emails?.some(e => !e.email?.trim().length)) {
        data.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
        let _uid = data.id
        if (!_uid) {
          _uid = uid()
        }
        data.pessoa.emails[0].email = `comunicacao-comitentes+${_uid}@suporteleiloes.com`
        /*this.$uloc.notify({
          message: 'Informe um e-mail para o comitente'
        })
        return*/
      }
      /*if (data.pessoa?.addresses?.some(a => !a.address?.trim().length)) {
        this.$uloc.notify({
          message: 'Informe um endereço para o comitente'
        })
        return
      }*/
      this.loading = true

      // Ajusta dados
      let phones = []
      this.comitente.pessoa.phoneNumbers.forEach((phone, index) => {
        if (phone.phoneNumber && String(phone.phoneNumber).length > 4) {
          phones.push(phone)
        }
      })
      data.pessoa.phoneNumbers = phones

      let contacts = []
      this.comitente.pessoa.contacts.forEach((contact, index) => {
        if (contact.value && String(contact.value).length > 2) {
          contacts.push(contacts)
        }
      })
      data.pessoa.contacts = contacts

      data.pessoa.addresses.map((address) => {
        if (address.cityId && address.cityId.id) {
          address.cityId = address.cityId.id
        }
        if (address.city && address.city.label) {
          address.city = address.city.label
        }
      })

      if (data.pessoa.addresses && data.pessoa.addresses.length) {
        if (!data.pessoa.addresses[0].zip || data.pessoa.addresses[0].zip === '')
        data.pessoa.addresses = null
      }

      if (data.pessoa.type === TIPO_PESSOA_JURIDICA) {
        data.pessoa.documents = null
      }

      if (data.config) {
        data.config = JSON.stringify(data.config)
      }

      this.status = 'Processando dados do comitente'
      let method = data.id ? updateComitente(data.id, data) : newComitente(data)
      method
        .then(({data}) => {
          this.comitente.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.load()
            this.dg()
            this.$nextTick(() => {
              // this.$refs.timer.start()
              /*window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000)*/
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              // this.$refs.timer.start()
            })
          }
          // Processa foto
          if (this.deleteFoto) {
            console.log('Deleta Foto')
          } else if (this.$imagem) {
            this.status = 'Analisando e processando arquivos do comitente'
            updateFotoComitente(this.comitente.id, {data: this.$imagem, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$imagem
              })
              .catch((error) => {
                alert('Impossível enviar a logomarca do comitente, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    adicionarEndereco () {
      this.comitente.pessoa.addresses.push(JSON.parse(JSON.stringify(mockEndereco)))
    },
    removeEndereco (endereco) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um endereço',
        message: 'Você tem certeza que deseja remover este endereço do cadastro do comitente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (endereco.id) {
          this.removeItem('enderecos', this.comitente.pessoa.id, endereco.id)
        }
        this.comitente.pessoa.addresses.splice(this.comitente.pessoa.addresses.indexOf(endereco), 1)
      }).catch(() => {
      })
    },
    getTelefoneLabel (index) {
      if (index === 0) {
        return 'Celular:'
      } else if (index === 1) {
        return 'Residencial:'
      }
      if (index === 2) {
        return 'Comercial:'
      }
      return ''
    },
    adicionarTelefone () {
      this.comitente.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
    },
    removeTelefone (telefone) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um telefone',
        message: 'Você tem certeza que deseja remover este telefone do cadastro do comitente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (telefone.id) {
          this.removeItem('telefones', this.comitente.pessoa.id, telefone.id)
        }
        this.comitente.pessoa.phoneNumbers.splice(this.comitente.pessoa.phoneNumbers.indexOf(telefone), 1)
      }).catch(() => {
      })
    },
    adicionarEmail () {
      this.comitente.pessoa.emails.push(JSON.parse(JSON.stringify(mockEmail)))
    },
    removeEmail (email) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um e-mail',
        message: 'Você tem certeza que deseja remover este e-mail do cadastro do comitente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (email.id) {
          this.removeItem('emails', this.comitente.pessoa.id, email.id)
        }
        this.comitente.pessoa.emails.splice(this.comitente.pessoa.emails.indexOf(email), 1)
      }).catch(() => {
      })
    },
    adicionarContato () {
      this.comitente.pessoa.contacts.push(JSON.parse(JSON.stringify(mockContato)))
    },
    removeContato (contato) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um contato',
        message: 'Você tem certeza que deseja remover este contato do cadastro do comitente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (contato.id) {
          this.removeItem('contatos', this.comitente.pessoa.id, contato.id)
        }
        this.comitente.pessoa.contacts.splice(this.comitente.pessoa.contacts.indexOf(contato), 1)
      }).catch(() => {
      })
    },
    removeItem (type, person, object) {
      removePersonObject(type, person, object).then(({data}) => {
        console.log(data)
      })
        .catch(error => {
          console.log(error)
          this.$nextTick(() => {
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    deleteImagemEvent () {
      this.deleteImagem = true
    },
    updateImagem (file) {
      this.$imagem = file

    }
  },
  components: {
    DocumentoSelect,
    GruposSelect,
    TipoPessoaSelect,
    Imagem,
    Log,
    TipoContatoSelect,
    TipoEmailSelect,
    TipoTelefoneSelect,
    UfSelect,
    CidadeSelect,
    TipoEnderecoSelect,
    Acesso,
    ApelidoInput,
    TipoComitenteSelect,
    ECol,
    ERow,
    StatusSelect,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    EnderecoV2,
    DocumentInput
  }
}
</script>
